<template>
  <div>
    <Topbar>
      <h2 class="title">Edit post #{{ post.id }}</h2>
      <div class="actions">
        <a href="#" class="important" v-on:click="save">
          <span>Save</span>
        </a>
        <a href="#" v-on:click="publish">
          <span>Publish</span>
        </a>
        <router-link to="/posts">
          <span>Back</span>
        </router-link>
      </div>
    </Topbar>
    <Spinner v-if="this.loading" />
    <form>
      <div>
        <Input v-model="post.title" label="Title" />
      </div>

      <TagInput v-model="post.keywords" label="Keywords" />

      <div>
        <ckeditor v-model="post.preamble" :config="preEditorConfig"></ckeditor>
      </div>


      <div>
        <ckeditor v-model="post.body" :config="mainEditorConfig"></ckeditor>
      </div>
    </form>
  </div>
</template>

<script>

import Api from '@/utils/api'
import Spinner from '@/components/Spinner'
import Topbar from '@/components/Topbar'
import Input from '@/components/Input'
import TagInput from '@/components/TagInput'

import CKEditor from 'ckeditor4-vue'
import UploadAdapter from '@/utils/upload_adapter'

export default {
  name: 'Edit',
  data() {
    return {
      preEditorConfig: {
        height: '500px',
        extraPlugins: ['uploadimage'],
        uploadUrl: '/api/v1/admin/pictures/',
        filebrowserUploadUrl: '/api/v1/admin/pictures/',
      },
      mainEditorConfig: {
        height: '1000px',
        extraPlugins: ['uploadimage'],
        uploadUrl: '/api/v1/admin/pictures/',
        filebrowserUploadUrl: '/api/v1/admin/pictures/',
      },
      url: "",
      post: {},
      loading: true
    }
  },
  methods: {
    save: function() {
      this.loading = true

      if(isNaN(this.$attrs.id)) {
        // create
        Api.post("post/", this.post, { json: true })
          .then((response) => {
            if(response.ok) {
              this.loading = false
              return response.json()
            } else {
              {}
            }
          })
          .then((data) => {
            this.$router.push({path: '/posts/' + data.id})
          })
      } else {
        // update
        Api.put("post/" + this.$attrs.id, this.post)
          .then((response) => {
            if (response.ok) {
              this.loading = false
              return response.json()
            } else {
              {}
            }
          })
          .then((data) => {
            console.log(data)
          })
      }
    },
    publish: function() {
      this.post.published = true
      this.save()
      console.log("publish")
    },
    uploader(editor)
    {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new UploadAdapter(loader, self);
      };
    },
  },
  mounted() {
    if(isNaN(this.$attrs.id) || !(parseInt(this.$attrs.id) > 0)) {
      this.loading = false
      return
    }

    this.loading = true

    Api.get("post/" + this.$attrs.id)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          {}
        }
      })
      .then((data) => {
        this.post = data
        this.loading = false
      })
  },
  components: {
    Spinner,
    Topbar,
    Input,
    TagInput,
    ckeditor: CKEditor.component
  }
}
</script>

<style>
</style>
