<template>
<div>
  <input
    class="input"
    type="text"
    :placeholder="label"
    :id="id"
    :required="required"
    :value="value"
    v-on:input="change" />
</div>
</template>

<script>
  export default {
    name: 'Input',
    props: {
      id: {
        type: String
      },
      value: {
        type: String
      },
      required: {
        type: Boolean,
        default: false
      },
      label: {
        type: String
      }
    },
    methods: {
      change: function(ev) {
        this.$emit('input', ev.target.value)
      }
    }
  }
</script>

<style scoped>
input {
  width: calc(100% - 0.25rem - 8px);
  padding: 0px 4px;
  border: 0;
  overflow: hidden;
  display: inline-block;
  text-align: start;
  margin-left: 0.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  vertical-align: middle;
}

input:focus {
  outline: 0;
}
</style>
