import { post } from '@/utils/api'

export default class UploadAdapter {
  constructor(loader, page) {
    console.log("constructor")
    console.log(loader)

    this.loader = loader
    this.page = page
  }

  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        this.page.loading = true

        var formData = new FormData();
        formData.append('upload', file);

        post("pictures", formData, { json: false })
          .then((response) => {
            if (response.ok) {
              return response.json()
            } else {
              {}
            }
          })
          .then((data) => {
            let url = data.url; // Get url from response
            console.log(url)

            resolve({
              default: url
            });

            this.page.loading = false
          })
          .catch((error) => {
            console.error('Error:', error)
            reject(error)
          })
      }))
  }

  abort() {
    console.log("abort")
  }
}
