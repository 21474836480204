<template>
<div class="tag_input">
  <span class="tag" v-for="(tag, index) of value" v-bind:id="index">
    {{ tag }}
    <span class="remove" v-on:click="del" />
  </span>
  <input
    class="input"
    type="text"
    :placeholder="label"
    :required="required"
    v-on:keyup.enter="add" />
</div>
</template>

<script>
  export default {
    name: 'TagInput',
    props: {
      id: {
        type: String
      },
      value: {
        type: Array,
        default: () => { return [] }
      },
      required: {
        type: Boolean,
        default: false
      },
      label: {
        type: String
      }
    },
    methods: {
      add: function(ev) {
        console.log(this.value)
        this.value.push(ev.target.value)
        ev.target.value = ""
        
        this.$emit('input', this.value)
      },
      del: function(ev) {
        this.value.splice(ev.target.id,1)

        this.$emit('input', this.value)
      }
    }
  }
</script>

<style scoped>
div.tag_input {
  overflow: hidden;
}

span.tag {
  display: inline-block;
  user-select: none;
  padding: 0.25rem;
  vertical-align: middle;
  margin-left: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  box-shadow: 0 0 5px rgb(0 0 0 / 70%);
  border-radius: 0.25rem;
  border-color: rgba(31,41,55,1);
  background-color: rgba(31,41,55,1);
  color: rgba(229,231,235,1);
}

input {
  padding: 0px 4px;
  border: 0;
  overflow: hidden;
  display: inline-block;
  text-align: start;
  margin-left: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 1rem;
  vertical-align: middle;
}

input:focus {
  outline: 0;
}

span.remove:before {
  display: inline-block;
  cursor: pointer;
  background-color: rgb(156 163 175);
  content: ' ';
  vertical-align: middle;
  margin-left: 0.25rem;
  height: 1rem;
  width: 1rem;
  -webkit-mask: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generated by IcoMoon.io --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%23000000' d='M15.854 12.854c-0-0-0-0-0-0l-4.854-4.854 4.854-4.854c0-0 0-0 0-0 0.052-0.052 0.090-0.113 0.114-0.178 0.066-0.178 0.028-0.386-0.114-0.529l-2.293-2.293c-0.143-0.143-0.351-0.181-0.529-0.114-0.065 0.024-0.126 0.062-0.178 0.114 0 0-0 0-0 0l-4.854 4.854-4.854-4.854c-0-0-0-0-0-0-0.052-0.052-0.113-0.090-0.178-0.114-0.178-0.066-0.386-0.029-0.529 0.114l-2.293 2.293c-0.143 0.143-0.181 0.351-0.114 0.529 0.024 0.065 0.062 0.126 0.114 0.178 0 0 0 0 0 0l4.854 4.854-4.854 4.854c-0 0-0 0-0 0-0.052 0.052-0.090 0.113-0.114 0.178-0.066 0.178-0.029 0.386 0.114 0.529l2.293 2.293c0.143 0.143 0.351 0.181 0.529 0.114 0.065-0.024 0.126-0.062 0.178-0.114 0-0 0-0 0-0l4.854-4.854 4.854 4.854c0 0 0 0 0 0 0.052 0.052 0.113 0.090 0.178 0.114 0.178 0.066 0.386 0.029 0.529-0.114l2.293-2.293c0.143-0.143 0.181-0.351 0.114-0.529-0.024-0.065-0.062-0.126-0.114-0.178z'%3E%3C/path%3E%3C/svg%3E%0A") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generated by IcoMoon.io --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%23000000' d='M15.854 12.854c-0-0-0-0-0-0l-4.854-4.854 4.854-4.854c0-0 0-0 0-0 0.052-0.052 0.090-0.113 0.114-0.178 0.066-0.178 0.028-0.386-0.114-0.529l-2.293-2.293c-0.143-0.143-0.351-0.181-0.529-0.114-0.065 0.024-0.126 0.062-0.178 0.114 0 0-0 0-0 0l-4.854 4.854-4.854-4.854c-0-0-0-0-0-0-0.052-0.052-0.113-0.090-0.178-0.114-0.178-0.066-0.386-0.029-0.529 0.114l-2.293 2.293c-0.143 0.143-0.181 0.351-0.114 0.529 0.024 0.065 0.062 0.126 0.114 0.178 0 0 0 0 0 0l4.854 4.854-4.854 4.854c-0 0-0 0-0 0-0.052 0.052-0.090 0.113-0.114 0.178-0.066 0.178-0.029 0.386 0.114 0.529l2.293 2.293c0.143 0.143 0.351 0.181 0.529 0.114 0.065-0.024 0.126-0.062 0.178-0.114 0-0 0-0 0-0l4.854-4.854 4.854 4.854c0 0 0 0 0 0 0.052 0.052 0.113 0.090 0.178 0.114 0.178 0.066 0.386 0.029 0.529-0.114l2.293-2.293c0.143-0.143 0.181-0.351 0.114-0.529-0.024-0.065-0.062-0.126-0.114-0.178z'%3E%3C/path%3E%3C/svg%3E%0A") no-repeat 50% 50%;
}
</style>
